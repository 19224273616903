<template>
  <div>
    <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar"
      type="button"
      class="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
      <span class="sr-only">Open sidebar</span>
      <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
        <path clip-rule="evenodd" fill-rule="evenodd"
          d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z">
        </path>
      </svg>
    </button>

    <aside id="logo-sidebar"
      class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
      aria-label="Sidebar">
      <div class="h-full px-3 py-4 overflow-y-auto bg-gray-50">
        <a href="#" class="flex items-center pl-2.5 mb-5">
          <img src="logo.jpg" class="object-cover rounded-full" style="height: 50px" alt="Văn Miếu Logo" width="50"
            height="50" />

          <span class="ml-4 self-center text-xl font-semibold whitespace-nowrap">{{ fullname }}</span>
        </a>

        <ul class="space-y-2 font-medium">
          <!-- v-if="permission.includes('Ticket.Views')" -->

          <li :class="{ active: isLinkActive('/') }" v-if="permission.includes('Dashboard.View')">
            <RouterLink to="/" class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group">
              <img src="/icon/report.png" width="24" />

              <span class="flex-1 ml-3 whitespace-nowrap">Báo cáo doanh thu</span>
            </RouterLink>
          </li>

          <li :class="{ active: isLinkActive('/show') }" v-if="permission.includes('Show.View')">
            <RouterLink to="/show" class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group">
              <img src="/icon/icon_show.png" width="24" />

              <span class="flex-1 ml-3 whitespace-nowrap">Buổi trình diễn
              </span>
            </RouterLink>
          </li>

          <li :class="{ active: isLinkActive('/calender') }" v-if="permission.includes('Calender.View')">
            <RouterLink to="/calender"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <img src="/icon/icon_calendar.png" width="24" />

              <span class="ml-3">Tạo lịch</span>
            </RouterLink>
          </li>

          <li :class="{ active: isLinkActive('/bookTicket') }" v-if="permission.includes('Booking.View')">
            <RouterLink to="/bookTicket"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <img src="/icon/icon_datve.png" width="24" />

              <span class="ml-3">Đặt vé trực tiếp</span>
            </RouterLink>
          </li>
          <li :class="{ active: isLinkActive('/bookTicketRestore') }" v-if="permission.includes('Booking.Delete')">
            <RouterLink to="/bookTicketRestore"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <img src="/icon/refresh.png" width="24" />
              <span class="ml-3">Khôi phục vé</span>
            </RouterLink>
          </li>
          <li :class="{ active: isLinkActive('/payment') }" v-if="permission.includes('Dashboard.Create')">
            <RouterLink to="/payment"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <img src="/icon/icon_datve.png" width="24" />

              <span class="ml-3">Cổng thanh toán</span>
            </RouterLink>
          </li>
          <li :class="{ active: isLinkActive('/giftTicket') }" v-if="permission.includes('Gift.View')">
            <RouterLink to="/giftTicket"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <img src="/icon/gift.png" width="24" />

              <span class="ml-3">Tặng Vé</span>
            </RouterLink>
          </li>
          <li>
            <button type="button"
              class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example" data-collapse-toggle="dropdown-example"
              :class="{ active: isLinkActive('/customer') }" v-if="permission.includes('Customer.View')">
              <img src="/icon/customer.png" width="24" />
              <div
                class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <span class="flex-1 ml-3 whitespace-nowrap">Khách hàng</span>
              </div>
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4" />
              </svg>
            </button>
            <ul id="dropdown-example" class="hidden py-2 space-y-2">
              <li :class="{ active: isLinkActive('/customer/check') }">
                <RouterLink to="/customer/check"
                  class="border  flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                  <span class="flex-1 ml-3 whitespace-nowrap">Đã thanh toán</span>
                </RouterLink>
              </li>
              <li :class="{ active: isLinkActive('/customer/nocheck') }">
                <RouterLink to="/customer/nocheck"
                  class="border flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">


                  <span class="flex-1 ml-3 whitespace-nowrap">Chưa thanh toán</span>
                </RouterLink>
              </li>

            </ul>
          </li>
          <li>
            <button type="button"
              class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-luhanh" data-collapse-toggle="dropdown-luhanh"
              v-if="permission.includes('Traveler.View')">
              <img src="/icon/travel.png" width="24" />

              <div
                class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <span class="flex-1 ml-3 whitespace-nowrap">Lữ Hành</span>
              </div>
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4" />
              </svg>
            </button>
            <ul id="dropdown-luhanh" class="hidden py-2 space-y-2">
              <li :class="{ active: isLinkActive('/traveler') }">
                <RouterLink to="/traveler"
                  class="border  flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                  <span class="flex-1 ml-3 whitespace-nowrap">Danh sách công ty mẹ </span>
                </RouterLink>
              </li>
              <li :class="{ active: isLinkActive('/traveler/child') }">
                <RouterLink to="/traveler/child"
                  class="border  flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                  <span class="flex-1 ml-3 whitespace-nowrap">Danh sách công ty con </span>
                </RouterLink>
              </li>
              <li :class="{ active: isLinkActive('/traveler/sale') }">
                <RouterLink to="/traveler/sale"
                  class="border flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">


                  <span class="flex-1 ml-3 whitespace-nowrap">Bán vé</span>
                </RouterLink>
              </li>

            </ul>
          </li>
          <li :class="{ active: isLinkActive('/changeTicket') }" v-if="permission.includes('ChangeTicket.View')">
            <RouterLink to="/changeTicket"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <img src="/icon/refresh.png" width="24" />

              <span class="flex-1 ml-3 whitespace-nowrap">Đổi vé</span>
            </RouterLink>
          </li>


          <li :class="{ active: isLinkActive('/tickertype') }" v-if="permission.includes('TicketType.View')">
            <RouterLink to="/tickertype"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <img src="/icon/icon_ticket.png" width="24" />

              <span class="flex-1 ml-3 whitespace-nowrap">Phân loại vé</span>
            </RouterLink>
          </li>
          <li :class="{ active: isLinkActive('/role') }" v-if="permission.includes('Role.View')">
            <RouterLink to="/role"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <img src="/icon/icon_role.png" width="24" />

              <span class="flex-1 ml-3 whitespace-nowrap">Phân Quyền</span>
            </RouterLink>
          </li>

          <li :class="{ active: isLinkActive('/api-key') }" v-if="permission.includes('ApiKey.View')">
            <RouterLink to="/api-key"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <img src="/icon/icon_key.png" width="24" />

              <span class="flex-1 ml-3 whitespace-nowrap">Api Key</span>
            </RouterLink>
          </li>
          
          <li :class="{ active: isLinkActive('/change-password') }" >
            <RouterLink to="/change-password"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <img src="/icon/icon_key.png" width="24" />

              <span class="flex-1 ml-3 whitespace-nowrap">Đổi mật khẩu</span>
            </RouterLink>
          </li>
          <li>
            <a href="#" @click="logOut"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <svg
                class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3" />
              </svg>
              <span class="flex-1 ml-3 whitespace-nowrap">Đăng Xuất</span>
            </a>
          </li>
        </ul>
      </div>
    </aside>
  </div>
</template>
<style scoped>
li.active {
  background: gainsboro;
  border-radius: 10px;
}

li.none {
  display: none;
}

button.active {
  background: gainsboro;
  border-radius: 10px;
}

button.none {
  display: none;
}
</style>
<script setup>
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'

// initialize components based on data attribute selectors
onMounted(() => {
  initFlowbite();
})
</script>

<script>
import store from "@/store/index.js";
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      fullname: ''
    };
  },
  computed: {
    ...mapState(["baseUrl", "store", "permission"]),
  },
  methods: {
    isLinkActive(route) {
      return this.$route.path === route;
    },
    test() {
      // console.log(store.state.baseUrl);
      store.commit("updateValuePage", {
        firstName: "John",
        lastName: "Doe",
        age: 46,
      });

      store.commit("updatePage", {
        firstName: "John",
        lastName: "Doe",
        age: 46,
      });
      console.log(this.store);
    },
    logOut() {
      const userInfoString = sessionStorage.getItem("userInfo");
      const userInfo = JSON.parse(userInfoString);
      const axiosInstance = axios.create({
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      axiosInstance
        .post(this.baseUrl + "/api/auth/logout")
        .then((response) => {
          if (response.status === 200) {
            sessionStorage.removeItem("userInfo");
            this.$router.push("/login");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    const userInfoString = sessionStorage.getItem("userInfo");
    const userInfo = JSON.parse(userInfoString);
    this.fullname = userInfo.fullname;
  },
};
</script>