import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { requireAuth } from '@/router/middleware/auth';
import Layout from '../views/Layout.vue';
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: Layout, // Sử dụng layout cho trang chính
      children: [
        {
          path: 'bookTicket',
          name: 'bookTicket',
          component: () => import('../views/TicketShowView.vue'),
          beforeEnter: requireAuth
        },
        {
          path: 'bookTicketRestore',
          name: 'bookTicketRestore',
          component: () => import('../views/TicketShowRestoreView.vue'),
          beforeEnter: requireAuth
        },
        {
          path: 'payment',
          name: 'payment',
          component: () => import('../views/PaymentShowView.vue'),
          beforeEnter: requireAuth
        },
        {
          path: 'giftTicket',
          name: 'giftTicket',
          component: () => import('../views/GiftTicketView.vue'),
          beforeEnter: requireAuth
        },
        {
          path: 'customer',
          name: 'customer',
          component: () => import('../views/ClientView.vue'),
          beforeEnter: requireAuth,
          // props: 'all'
        },
        {
          path: 'traveler',
          name: 'traveler',
          component: () => import('../views/TravelerView.vue'),
          beforeEnter: requireAuth,
          // props: 'all'
        },
        {
          path: 'traveler/child',
          name: 'traveler/child',
          component: () => import('../views/TravelerChildView.vue'),
          beforeEnter: requireAuth,
          // props: 'all'
        },
        {
          path: 'traveler/sale',
          name: 'traveler/sale',
          component: () => import('../views/SaleTravelerView.vue'),
          beforeEnter: requireAuth,
          // props: 'all'
        },
        {
          path: 'changeTicket',
          name: 'changeTicket',
          component: () => import('../views/ChangeTicketView.vue'),
          beforeEnter: requireAuth,
          // props: 'all'
        },
        {
          path: 'customer/check',
          name: 'customer/check',
          component: () => import('../views/ClientView.vue'),
          beforeEnter: requireAuth,
          props: { default: true }
        },
        {
          path: 'customer/nocheck',
          name: 'customer/nocheck',
          component: () => import('../views/ClientView.vue'),
          beforeEnter: requireAuth,
          // props: 'nocheck'
        },
        {
          path: 'calender',
          name: 'calender',
          component: () => import('../views/CalenderView.vue'),
          beforeEnter: requireAuth

        },
        {
          path: 'show',
          name: 'show',
          component: () => import('../views/ShowView.vue'),
          beforeEnter: requireAuth

        },
        {
          path: 'tickertype',
          name: 'tickertype',
          component: () => import('../views/TicketTypeView.vue'),
          beforeEnter: requireAuth
        },
        {
          path: 'api-key',
          name: 'api-key',
          component: () => import('../views/ApiKeyView.vue'),
          beforeEnter: requireAuth
        },
        {
          path: 'change-password',
          name: 'change-password',
          component: () => import('../views/ChangePasswordView.vue'),
          beforeEnter: requireAuth
        },
      {
        path: '',
        name: 'chart',
        component: () => import('../views/ChartView.vue'),
        beforeEnter: requireAuth
      },
        {
          path: 'role',
          name: 'role',
          component: () => import('../views/RoleView.vue'),
          beforeEnter: requireAuth
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../components/Login.vue'),
    }

  ]
 
});
export default router;
